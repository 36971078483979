import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { StyledInput } from "../Styled/FormElements"
import { StyledButton } from "../Styled/Button"

const Wrapper = tw.div`
  min-h-full bg-lightest p-2-4 w-full md:p-3-6 lg:px-7-2 lg:py-4-8
`

const Title = tw.h2`
  mb-4-8 text-22 leading-1.32 hidden md:block font-medium
`

const Content = tw.div`
  flex flex-col md:flex-row justify-start
`

const Input = styled(StyledInput)`
  ${tw`md:max-w-25 mb-2-4 md:mb-0 md:mr-3-2`}
`

const Button = styled(StyledButton)`
  ${tw`md:max-w-19 w-full`}
`

const AccountGiftCard = () => {
  return (
    <Wrapper>
      <Title>Gift card & in store credit</Title>
      <Content>
        <Input placeholder={`Code`} />
        <Button height={`56`} textSize={`16`} colour={`green`}>
          Check balance
        </Button>
      </Content>
    </Wrapper>
  )
}

export default AccountGiftCard
