import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { ProductCardProps } from "../../props/ProductProps"
import WishlistCard from "../Wishlist/WishlistCard"

const Wrapper = tw.div`
  min-h-full bg-lightest p-2-4 w-full md:p-3-6 lg:px-7-2 lg:py-4-8
`

const Title = tw.h2`
  mb-4-8 text-22 leading-1.32 hidden md:block font-medium
`

const Content = tw.div`
  flex flex-wrap
`

const Card = styled(WishlistCard)`
  ${tw`w-full md:w-1/2`}
`

const AccountWishlist = ({ products }) => {
  return (
    <Wrapper>
      <Title>Wishlist</Title>
      <Content>
        {products.map((product, index) => (
          <Card key={index} product={product} />
        ))}
      </Content>
    </Wrapper>
  )
}

AccountWishlist.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape(ProductCardProps)),
}

export default AccountWishlist
