import React, { useState } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import ModalsPopup from "../Modals/ModalsPopup"
import AccountOrder from "./AccountOrder"
import AccountOrderDetails from "./AccountOrderDetails"
import { OrdersProps } from "../../props/AccountProps"

const Wrapper = tw.div`
  min-h-full bg-lightest p-2-4 pt-0-8 w-full md:p-3-6 lg:px-7-2 lg:py-4-8
`

const Title = tw.h2`
  mb-2-4 text-22 leading-1.32 hidden md:block font-medium
`

const Content = tw.div`
  flex flex-col
`

const Order = styled(AccountOrder)`
  ${tw`py-2-4`}
  ${({ last }) => (last ? tw`border-0` : tw`border-b border-beige`)}
`

const Desktop = tw.div`
  hidden md:block -mx-1-6
`

const Mobile = tw.div`
  md:hidden
`

const ModalsContent = tw.div`
  w-full py-3-2 px-0-8
`

const INITIAL_STATE = {
  id: "",
  orderNumber: 0,
  totalPrice: {},
  shippingTotalPrice: {},
  subTotalPrice: {},
  status: "",
  lineItems: [],
  processedAt: "",
  shippingAddress: {},
}

const AccountOrders = ({ orders }) => {
  const [editActive, setEditActive] = useState(false)
  const [orderData, setOrderData] = useState(INITIAL_STATE)

  const handleClick = id => {
    setOrderData({ ...orders.find(order => order.id === id) })
    setEditActive(true)
  }

  return (
    <Wrapper>
      {!editActive ? (
        <>
          <Title>My purchases</Title>
          <Content>
            {orders.map((order, index) => (
              <Order key={order.id} order={order} handleView={handleClick} last={index === orders.length - 1} />
            ))}
          </Content>
        </>
      ) : (
        <Desktop>
          <AccountOrderDetails order={orderData} setActive={setEditActive} />
        </Desktop>
      )}
      <Mobile>
        <ModalsPopup active={editActive} setActive={setEditActive}>
          <ModalsContent>
            <AccountOrderDetails order={orderData} setActive={setEditActive} />
          </ModalsContent>
        </ModalsPopup>
      </Mobile>
    </Wrapper>
  )
}

AccountOrders.propTypes = {
  orders: OrdersProps,
}

export default AccountOrders
