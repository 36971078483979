import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { CountryRegionData } from "react-country-region-selector"

import { UserProps, UserCustomProps } from "../../props/AccountProps"
import { StyledButton } from "../Styled/Button"
import Icon from "../Icon"
import FormInput from "../Form/FormInput"
import FormCheckbox from "../Form/FormCheckbox"
import FormDropdown from "../Form/FormDropdown"
import FormBirthday from "../Form/FromBirthday"

const Title = tw.h2`
  mb-3-2 md:mb-4-8 text-18 md:text-22 leading-1.32 font-medium px-1-6
`

const Wrapper = tw.div`
  flex flex-wrap w-full
`

const Input = styled(FormInput)`
  ${tw`mb-2-8`}
`

const StyledIcon = styled(Icon)`
  ${tw`absolute right-1-6 top-0-6`}
`

const InputWrapper = tw.div`
  relative w-full
`

const Checkbox = styled(FormCheckbox)`
  ${tw`mb-2-8 ml-1-6 w-full`}
`

const Dropdown = styled(FormDropdown)`
  ${tw`mb-2-8 w-full md:w-1/2 px-1-6`}
`

const Birthday = styled(FormBirthday)`
  ${tw`mb-2-8 w-full md:w-1/2`}
`

const Buttons = tw.div`
  w-full flex flex-wrap mt-0-8
`

const ButtonWrapper = tw.div`
  px-1-6 w-full md:w-1/2
`

const Submit = styled(StyledButton)`
  ${tw`w-full`}
`

const Cancel = styled(StyledButton)`
  ${tw`hidden md:block w-full`}
`

const AccountDetailsForm = ({ reference, user, userCustom, setActive }) => {
  const [userData, setUserDate] = useState(user)
  const [userCustomData, setUserCustomData] = useState(userCustom)
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  const country = CountryRegionData.map(country => ({ label: country[0], value: country[0] }))

  const state = userCustomData.country
    ? CountryRegionData.find(country => country[0] === userCustomData.country)[2]
        .split(`|`)
        .map(state => ({ label: state.split(`~`)[0], value: state.split(`~`)[0] }))
    : []

  const referenceData = reference.map(item => ({ label: item, value: item }))

  const handleChange = ({ target: { name, value } }) => {
    setUserDate(prevUserData => {
      return {
        ...prevUserData,
        [name]: value,
      }
    })
  }

  const handleCustomChange = ({ target: { name, value } }) => {
    setUserCustomData(prevUserCustomData => {
      return {
        ...prevUserCustomData,
        [name]: value,
      }
    })
  }

  const handleProfessionalChange = () => {
    setUserCustomData(prevUserCustomData => {
      return {
        ...prevUserCustomData,
        healthProfessional: !prevUserCustomData.healthProfessional,
      }
    })
  }

  return (
    <>
      <Title>My details</Title>
      <Wrapper>
        <Input
          name={`firstName`}
          width={`1/2`}
          placeholder={`First name`}
          value={userData.firstName}
          handleChange={handleChange}
        />
        <Input
          name={`lastName`}
          width={`1/2`}
          placeholder={`Last name`}
          value={userData.lastName}
          handleChange={handleChange}
        />
        <Input placeholder={`Email`} value={userData.email} readOnly />
        <Input
          name={`phone`}
          placeholder={`Phone`}
          value={userCustomData.phone}
          handleChange={handleCustomChange}
        />
        <InputWrapper>
          <Input
            type={showPassword ? `text` : `password`}
            placeholder={`Change password`}
            value={password}
            handleChange={e => setPassword(e.target.value)}
          />
          <StyledIcon
            name={!showPassword ? `show` : `hide`}
            size={`xsmall`}
            onClick={() => setShowPassword(!showPassword)}
          />
        </InputWrapper>
        <Birthday birthday={userCustomData.birthday} />
        <Dropdown
          name={`reference`}
          placeholder={`How did you hear about us?*`}
          options={referenceData}
          value={userCustomData.reference}
          handleChange={(value, name) => handleCustomChange({ target: { name, value } })}
        />
        <Checkbox
          label={`I am a health professional`}
          value={userCustomData.healthProfessional}
          handleChange={handleProfessionalChange}
        />
        {userCustomData.healthProfessional && (
          <>
            <Input
              name={`type`}
              width={`1/2`}
              placeholder={`Type of health professional*`}
              value={userCustomData.type}
              handleChange={handleCustomChange}
            />
            <Input
              name={`name`}
              width={`1/2`}
              placeholder={`Clinic name*`}
              value={userCustomData.name}
              handleChange={handleCustomChange}
            />
            <Input
              name={`address`}
              placeholder={`Clinic address*`}
              value={userCustomData.address}
              handleChange={handleCustomChange}
            />
            <Input
              name={`city`}
              width={`1/2`}
              placeholder={`City/suburb*`}
              value={userCustomData.city}
              handleChange={handleCustomChange}
            />
            <Dropdown
              disable={!userCustomData.country}
              name={`state`}
              placeholder={`State/province*`}
              options={state}
              value={userCustomData.state}
              handleChange={(value, name) => handleCustomChange({ target: { name, value } })}
            />
            <Input
              name={`postcode`}
              width={`1/2`}
              placeholder={`Postal code*`}
              value={userCustomData.postcode}
              handleChange={handleCustomChange}
            />
            <Dropdown
              name={`country`}
              placeholder={`Country*`}
              options={country}
              value={userCustomData.country}
              handleChange={(value, name) => handleCustomChange({ target: { name, value } })}
            />
            <Input
              name={`ahpra`}
              width={`1/2`}
              placeholder={`AHPRA number*`}
              value={userCustomData.ahpra}
              handleChange={handleCustomChange}
            />
          </>
        )}
        <Buttons>
          <ButtonWrapper>
            <Submit height={`56`} textSize={`16`} colour={`green`} border>
              Save changes
            </Submit>
          </ButtonWrapper>
          <ButtonWrapper>
            <Cancel height={`56`} textSize={`16`} border onClick={() => setActive(false)}>
              Cancel
            </Cancel>
          </ButtonWrapper>
        </Buttons>
      </Wrapper>
    </>
  )
}

AccountDetailsForm.propTypes = {
  reference: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.shape(UserProps),
  userCustom: PropTypes.shape(UserCustomProps),
  setActive: PropTypes.func,
}

export default AccountDetailsForm
