import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { OrderProps } from "../../props/AccountProps"
import { StyledButton } from "../Styled/Button"
import Image from "../Image/Image"

const Wrapper = styled.div`
  ${tw`w-full flex flex-col justify-between`}
  ${({ out }) => (!out ? tw`md:flex-row` : null)}
  ${({ center }) => (center ? tw`items-center` : null)}
`

const Text = styled.p`
  ${({ status }) => (status ? tw`text-orange` : null)}
  ${({ date }) => (date ? tw`text-13 leading-1.69` : tw`text-16 leading-2 font-medium`)}
`

const Images = tw.div`
  w-full md:w-1/2 flex flex-row justify-center md:justify-start
`

const ImageWrapper = tw.div`
  w-1/2
`

const Buttons = tw.div`
  flex flex-col md:flex-row w-full md:w-1/2
`

const ButtonWrapper = tw.div`
  p-0-8
`

const Button = styled(StyledButton)`
  ${tw`w-full md:w-15`}
`

const AccountOrder = ({ order, handleView, className }) => {
  return (
    <Wrapper out className={className}>
      <Wrapper>
        <Text>{`Order #${order.orderNumber}`}</Text>
        <Text status>{order.status}</Text>
      </Wrapper>
      <Text date>{`Purchased ${order?.shippingAddress ? `online` : `in store`} on ${
        order.processedAt
      }`}</Text>
      <Wrapper center>
        <Images>
          {order.lineItems.map((lineItem, index) => (
            <ImageWrapper key={index}>
              <Image image={lineItem.image} ratio={`1/1`} />
            </ImageWrapper>
          ))}
        </Images>
        <Buttons>
          <ButtonWrapper>
            <Button height={`56`} textSize={`16`} border onClick={() => handleView(order.id)}>
              View Order
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button height={`56`} textSize={`16`} border>
              Track Order
            </Button>
          </ButtonWrapper>
        </Buttons>
      </Wrapper>
    </Wrapper>
  )
}

AccountOrder.propTypes = {
  order: PropTypes.shape(OrderProps),
  handleView: PropTypes.func,
  className: PropTypes.string,
}

export default AccountOrder
