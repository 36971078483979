import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import { StyledButton } from "../Styled/Button"
import Accordion from "../Accordion"
import AccountDetails from "./AccountDetails"
import AccountAddresses from "./AccountAddresses"
import AccountOrders from "./AccountOrders"
import AccountWishlist from "./AccountWishlist"
import AccountGiftCard from "./AccountGiftCard"
import AccountLoyalty from "./AccountLoyalty"
import { UserProps, UserCustomProps, AddressesProps, OrdersProps } from "../../props/AccountProps"
import { ProductCardProps } from "../../props/ProductProps"

const Container = styled(StyledContainer)`
  ${tw``}
`

const Desktop = tw.div`
  hidden md:flex flex-row pt-2-4 pb-7-2
`

const Mobile = tw.div`
  block md:hidden pb-4
`

const Nav = tw.div`
  min-w-22
`

const List = tw.ul`
  flex flex-col items-start
`

const ListItem = styled.button`
  ${tw`text-16 lg:text-18 focus:outline-none`}
  ${({ active }) => (active ? tw`font-medium underline` : null)}
  ${({ logout }) => (logout ? tw`mt-8 mb-0` : tw`mb-4`)}
`

const Spacing = tw.div`
  lg:min-w-22
`

const Content = tw.div`
  max-w-77 mx-auto w-full
`

const Wrapper = tw.div`
  -mx-2-4 mb-3-2 border-b border-grey
`

const Logout = styled(StyledButton)`
  ${tw`w-full`}
`

const Account = ({ reference, user, userCustom, orders, addresses, wishlistProducts }) => {
  const NAV_ITEMS = [
    {
      title: `My details`,
      handle: `details`,
      component: <AccountDetails user={user} userCustom={userCustom} reference={reference} />,
    },
    {
      title: `My addresses`,
      handle: `addresses`,
      component: <AccountAddresses addresses={addresses} />,
    },
    {
      title: `My purchases`,
      handle: `orders`,
      component: <AccountOrders orders={orders} />,
    },
    {
      title: `My wishlist`,
      handle: `wishlist`,
      component: <AccountWishlist products={wishlistProducts} />,
    },
    {
      title: `Gift card & in store credit`,
      handle: `giftcard`,
      component: <AccountGiftCard />,
    },
    {
      title: `VIP status`,
      handle: `vip`,
      component: <AccountLoyalty />,
    },
  ]
  const [activeNavItem, setActiveNavItem] = useState(NAV_ITEMS[0].handle)

  return (
    <BackgroundContainer background={`light`}>
      <Container>
        <Desktop>
          <Nav>
            <List>
              {NAV_ITEMS.map(item => (
                <ListItem key={item.title} active={activeNavItem === item.handle} onClick={() => setActiveNavItem(item.handle)}>
                  {item.title}
                </ListItem>
              ))}
              <ListItem logout>Log out</ListItem>
            </List>
          </Nav>
          <Content>{NAV_ITEMS.find(item => item.handle === activeNavItem).component}</Content>
          <Spacing />
        </Desktop>
        <Mobile>
          <Wrapper>
            {NAV_ITEMS.map(item => (
              <Accordion key={item.title} title={item.title} size={`account`} icon>
                {item.component}
              </Accordion>
            ))}
          </Wrapper>
          <Logout height={`56`} textSize={`16`} border>
            Log out
          </Logout>
        </Mobile>
      </Container>
    </BackgroundContainer>
  )
}

Account.propTypes = {
  reference: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.shape(UserProps),
  userCustom: PropTypes.shape(UserCustomProps),
  addresses: AddressesProps,
  orders: OrdersProps,
  wishlistProducts: PropTypes.arrayOf(PropTypes.shape(ProductCardProps)),
}

export default Account
