import PropTypes from "prop-types"

export const UserProps = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
}

export const UserCustomProps = {
  phone: PropTypes.string,
  birthday: PropTypes.shape({
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
  }),
  reference: PropTypes.string,
  healthProfessional: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postcode: PropTypes.string,
  country: PropTypes.string,
  ahpra: PropTypes.string,
}

export const AddressProps = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postcode: PropTypes.string,
  country: PropTypes.string,
  parimary: PropTypes.bool,
  tag: PropTypes.string,
}

export const AddressesProps = PropTypes.arrayOf(PropTypes.shape(AddressProps))

export const OrderLineItem = {
  image: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  selectedOptions: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  price: {
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
  },
  quantity: PropTypes.number,
}

export const OrderProps = {
  id: PropTypes.string,
  orderNumber: PropTypes.number,
  totalPrice: {
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
  },
  shippingTotalPrice: {
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
  },
  subTotalPrice: {
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
  },
  status: PropTypes.string,
  lineItems: PropTypes.arrayOf(PropTypes.shape(OrderLineItem)),
  processedAt: PropTypes.string,
  link: PropTypes.string,
  shippingAddress: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
  }),
}

export const OrdersProps = PropTypes.arrayOf(PropTypes.shape(OrderProps))
