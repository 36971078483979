import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate, Link } from "gatsby"

import { ProductCardProps } from "../../props/ProductProps"
import Image from "../Image/Image"
import FormDrapdown from "../Form/FormDropdown"
import AddToCart from "../Product/AddToCart/AddToCart"
import Price from "../Price/Price"
import Icon from "../Icon"

const Wrapper = tw.div`
  px-1-6 py-2-4 flex flex-wrap border-t border-beige md:border-0 relative
`

const ImageWrapper = styled(Link)`
  ${tw`w-1/3 md:w-full relative`}
`

const Badge = styled.span`
  ${tw`absolute left-0-8 md:left-1-6 top-0-8 md:top-1-6 text-12 md:text-18 leading-2 md:leading-2.25 px-1-6`}
  ${({ colour }) => (colour === `dark` ? tw`text-lightest bg-dark` : tw`text-dark bg-light`)}
  ${({ sale }) => (sale ? tw`md:px-3` : null)}
`

const Close = styled(Icon)`
  ${tw`absolute text-grey cursor-pointer`}
  ${({ desktop }) => (desktop ? tw`hidden md:block right-1-6 top-1-6` : null)}
  ${({ mobile }) => (mobile ? tw`md:hidden right-0-8 top-0-8` : null)}
`

const DetailsWrapper = tw.div`
  w-2/3 md:w-full pl-2-4 md:pl-0
`

const Details = tw.div`
  flex flex-wrap md:py-1-6
`

const Title = styled(Link)`
  ${tw`flex-1 pr-3-6 mb-0-8 md:mb-0`}
`

const Text = styled.p`
  ${tw`text-14 md:text-16 leading-1.75 md:leading-1.31 inline `}
  ${({ bold }) => (bold ? tw`font-medium` : null)}
`

const SelectorWrapper = tw.div`
  mt-2-4 md:mt-0
`

const DropdownSelector = styled(FormDrapdown)`
  ${tw`mb-0-8`}
`

const WishlistCard = ({ product, className }) => {
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0])

  const options = product.variants.map(variant => {
    return {
      label: variant.available
        ? variant.selectedOptions[0].value
        : product.notify
        ? `${variant.selectedOptions[0].value} - Out of stock`
        : `${variant.selectedOptions[0].value} - Sold out`,
      value: variant.selectedOptions[0].value,
      available: variant.available,
    }
  })

  const handleChange = ({ target: { value } }) => {
    const newSelectedVariant = product.variants.find(variant => variant.selectedOptions[0].value === value)
    setSelectedVariant(newSelectedVariant)
  }

  return (
    <Wrapper className={className}>
      <Close mobile name={`close`} size={`xsmall`} />
      <ImageWrapper to={product.link}>
        <Image image={product.images[0].src} hoverImage={product.images[1].src} ratio={`1/1`} onClick={() => navigate(product.link)} />
        <Badge colour={product.badge?.colour} sale={product.badge?.title.toLowerCase() === `sale`}>
          {product.badge?.title}
        </Badge>
        <Close desktop name={`close`} size={`small`} />
      </ImageWrapper>
      <DetailsWrapper>
        <Details>
          <Title to={product.link}>
            <Text bold>{product.title}&nbsp;</Text>
            <Text>{product.subTitle}</Text>
          </Title>
          <Price presentmentPrices={selectedVariant.presentmentPrices} layout={`wishlist`} />
        </Details>
        <SelectorWrapper>
          <DropdownSelector
            name={`selector`}
            options={options}
            placeholder={`Select size`}
            handleChange={value => handleChange({ target: { value } })}
            layout={`wishlist`}
          />
          <AddToCart notify={product.notify} selectedVariant={selectedVariant} wishlist />
        </SelectorWrapper>
      </DetailsWrapper>
    </Wrapper>
  )
}

WishlistCard.props = {
  product: PropTypes.shape(ProductCardProps),
  className: PropTypes.string,
}

export default WishlistCard
