import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withAddToCart } from "./withAddToCart"
import { StyledButton } from "../../Styled/Button"
import { VariantProps } from "../../../props/VariantProps"
import BackInStock from "../BackInStock/BackInStock"

const Wrapper = styled.div`
  ${({ wishlist }) => (!wishlist ? tw`mb-1-6` : null)}
`

const Button = styled(StyledButton)`
  ${tw`w-full`}
`

const AddToCart = withAddToCart(({ notify, defaultVariant, selectedVariant, wishlist, active, setActive, addToCart, loading, locating }) => (
  <>
    <Wrapper wishlist={wishlist}>
      {!selectedVariant ? (
        <Button
          height={wishlist ? `48` : `56`}
          textSize={wishlist ? `16-14` : `16`}
          colour={`green`}
          onClick={() => addToCart(defaultVariant?.id)}
          disabled={loading || locating}
        >
          Add to cart
        </Button>
      ) : selectedVariant?.availableForSale ? (
        <Button
          height={wishlist ? `48` : `56`}
          textSize={wishlist ? `16-14` : `16`}
          colour={`green`}
          onClick={() => addToCart(selectedVariant?.id)}
          disabled={loading || locating}
        >
          Add to cart
        </Button>
      ) : notify ? (
        <Button height={wishlist ? `48` : `56`} textSize={wishlist ? `16-14` : `16`} colour={`green`} onClick={() => setActive(true)}>
          Email me when restocked
        </Button>
      ) : (
        <Button height={wishlist ? `48` : `56`} textSize={wishlist ? `16-14` : `16`} colour={`green`}>
          Sold out
        </Button>
      )}
    </Wrapper>
    <BackInStock selectedVariant={selectedVariant} active={active} setActive={setActive} />
  </>
))

AddToCart.propTypes = {
  notify: PropTypes.bool,
  defaultVariant: PropTypes.shape(VariantProps),
  selectedVariant: PropTypes.shape(VariantProps),
  className: PropTypes.string,
  wishlist: PropTypes.bool,
}

export default AddToCart
