import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

import Icon from "../Icon"

const Wrapper = tw.div`
  flex flex-row items-center cursor-pointer
`

const Label = tw.span`
  inline-block text-16 leading-2 ml-0-8
`

const FormCheckbox = ({ value, handleChange, label, className }) => {
  return (
    <Wrapper onClick={handleChange} className={className}>
      <Icon name={!value ? `tickedBoxInactive` : `tickedBoxActive`} size={`xxs`} />
      <Label>{label}</Label>
    </Wrapper>
  )
}

FormCheckbox.propTypes = {
  value: PropTypes.bool,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
}

export default FormCheckbox
