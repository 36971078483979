import React, { useState, useEffect } from "react"

import { useApp } from "../../../hooks/useApp"

export const withBackInStock = Component => ({ name = "BackInStock", selectedVariant, active, setActive }) => {
  const {
    config: {
      services: { forms },
    },
  } = useApp()
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(null)
  const [email, setEmail] = useState(``)

  const buildNotifyRequest = () => ({
    data: {
      email,
      variant: selectedVariant.id,
    },
  })

  const handleSubmit = async event => {
    event.preventDefault()

    if (email && selectedVariant) {
      setLoading(true)

      try {
        await fetch(forms.notify, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(buildNotifyRequest()),
        })
        setLoading(false)
        setSent(true)
      } catch (error) {
        setLoading(false)
        setError(error)
      }
    }
  }

  useEffect(() => {
    if (active) {
      setSent(false)
    }
  }, [active])

  Component.displayName = name

  return (
    <Component
      loading={loading}
      sent={sent}
      error={error}
      email={email}
      setEmail={setEmail}
      handleSubmit={handleSubmit}
      active={active}
      setActive={setActive}
    />
  )
}
