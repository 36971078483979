import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import FormDropdown from "./FormDropdown"

const Wrapper = tw.div`
  flex flex-row
`

const Dropdown = styled(FormDropdown)`
  ${tw`w-1/3 px-1-6`}
`

const generateArray = (start, end) => {
  return Array.from(new Array(end + 1).keys())
    .slice(start)
    .map(i => ({ label: i.toString(), value: i.toString() }))
}

const DayData = generateArray(1, 31)
const YearData = generateArray(1900, 2010)
const MonthData = [
  `Jan`,
  `Feb`,
  `Mar`,
  `Apr`,
  `May`,
  `Jun`,
  `Jul`,
  `Aug`,
  `Sep`,
  `Otc`,
  `Nov`,
  `Dec`,
].map(month => ({ label: month, value: month }))

const FormBirthday = ({ birthday, className }) => {
  const [day, setDay] = useState(birthday.day)
  const [month, setMonth] = useState(birthday.month)
  const [year, setYear] = useState(birthday.year)
  return (
    <Wrapper className={className}>
      <Dropdown
        placeholder={`DD`}
        options={DayData}
        value={day}
        handleChange={value => setDay(value)}
      />
      <Dropdown
        placeholder={`MM`}
        options={MonthData}
        value={month}
        handleChange={value => setMonth(value)}
      />
      <Dropdown
        placeholder={`YYYY`}
        options={YearData}
        value={year}
        handleChange={value => setYear(value)}
      />
    </Wrapper>
  )
}

FormBirthday.propTypes = {
  birthday: PropTypes.shape({
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
  }),
  className: PropTypes.string,
}

export default FormBirthday
