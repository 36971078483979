import React from "react"
import tw from "twin.macro"

const Wrapper = tw.div`
  min-h-full bg-lightest p-2-4 w-full md:p-3-6 lg:px-7-2 lg:py-4-8
`

const Title = tw.h2`
  mb-4-8 text-22 leading-1.32 hidden md:block font-medium
`

const Content = tw.div`
  flex flex-col justify-start
`

const Text = tw.span`
  text-16 leading-2
`

const AccountLoyalty = () => {
  return (
    <Wrapper>
      <Title>Gift card & in store credit</Title>
      <Content>
        <Text>Current status: Not yet VIP</Text>
        <Text>Total spent in the last 18 months: $0.00</Text>
        <Text>Spend $1,500.00 to become a VIP.</Text>
      </Content>
    </Wrapper>
  )
}

export default AccountLoyalty
