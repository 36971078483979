import React, { useState } from "react"

import { useCart } from "../../../hooks/useCart"
import { useLocation } from "../../../hooks/useLocation"

export const withAddToCart = Component => ({ name = "AddToCart", notify, defaultVariant, selectedVariant, wishlist }) => {
  const { locating } = useLocation()
  const { addToCart, loading } = useCart()
  const [backToStockActive, setBackToStockActive] = useState(false)

  Component.displayName = name
  return (
    <Component
      notify={notify}
      defaultVariant={defaultVariant}
      selectedVariant={selectedVariant}
      wishlist={wishlist}
      active={backToStockActive}
      setActive={setBackToStockActive}
      addToCart={addToCart}
      loading={loading}
      locating={locating}
    />
  )
}
