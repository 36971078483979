import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { CountryRegionData } from "react-country-region-selector"

import { AddressProps } from "../../props/AccountProps"
import { StyledButton } from "../Styled/Button"
import FormInput from "../Form/FormInput"
import FormCheckbox from "../Form/FormCheckbox"
import FormDropdown from "../Form/FormDropdown"

const Title = tw.h2`
  mb-3-2 md:mb-4-8 text-18 md:text-22 leading-1.32 font-medium px-1-6
`

const Wrapper = tw.div`
  flex flex-wrap w-full
`

const Input = styled(FormInput)`
  ${tw`mb-2-8`}
`

const Checkbox = styled(FormCheckbox)`
  ${tw`mb-2-8 ml-1-6 w-full`}
`

const Dropdown = styled(FormDropdown)`
  ${tw`mb-2-8 w-full md:w-1/2 px-1-6`}
`

const Buttons = tw.div`
  w-full flex flex-wrap mt-0-8
`

const ButtonWrapper = tw.div`
  px-1-6 w-full md:w-1/2
`

const Submit = styled(StyledButton)`
  ${tw`w-full`}
`

const Cancel = styled(StyledButton)`
  ${tw`hidden md:block w-full`}
`

const AccountAddressForm = ({ address, setActive }) => {
  const [addressEditData, setAddressEditData] = useState(address)

  const country = CountryRegionData.map(country => ({ label: country[0], value: country[0] }))

  const state = addressEditData?.country
    ? CountryRegionData.find(country => country[0] === addressEditData.country)[2]
        .split(`|`)
        .map(state => ({ label: state.split(`~`)[0], value: state.split(`~`)[0] }))
    : []

  const handleChange = ({ target: { name, value } }) => {
    setAddressEditData(prevAddressData => {
      return {
        ...prevAddressData,
        [name]: value,
      }
    })
  }

  const handlePrimaryChange = () => {
    setAddressEditData(prevAddressData => {
      return {
        ...prevAddressData,
        primary: !prevAddressData.primary,
      }
    })
  }

  return (
    <>
      <Title>{addressEditData?.id ? `My addresses` : `Add a new address`}</Title>
      <Wrapper>
        <Input
          name={`tag`}
          placeholder={`Tag`}
          value={addressEditData.tag}
          handleChange={handleChange}
        />
        <Input
          name={`firstName`}
          width={`1/2`}
          placeholder={`First name`}
          value={addressEditData.firstName}
          handleChange={handleChange}
        />
        <Input
          name={`lastName`}
          width={`1/2`}
          placeholder={`Last name`}
          value={addressEditData.lastName}
          handleChange={handleChange}
        />
        <Input
          name={`address1`}
          placeholder={`Company`}
          value={addressEditData.address1}
          handleChange={handleChange}
        />
        <Input
          name={`address2`}
          placeholder={`Company`}
          value={addressEditData.address2}
          handleChange={handleChange}
        />
        <Input
          name={`city`}
          width={`1/2`}
          placeholder={`City/suburb`}
          value={addressEditData.lastName}
          handleChange={handleChange}
        />
        <Dropdown
          name={`country`}
          placeholder={`Country*`}
          options={country}
          value={addressEditData.country}
          handleChange={(value, name) => handleChange({ target: { name, value } })}
        />
        <Dropdown
          disable={!addressEditData.country}
          name={`state`}
          placeholder={`State/province*`}
          options={state}
          value={addressEditData.state}
          handleChange={(value, name) => handleChange({ target: { name, value } })}
        />
        <Input
          name={`postcode`}
          width={`1/2`}
          placeholder={`Postal code*`}
          value={addressEditData.postcode}
          handleChange={handleChange}
        />
        <Checkbox
          label={`Set as default address`}
          value={addressEditData.primary}
          handleChange={handlePrimaryChange}
        />
        <Buttons>
          <ButtonWrapper>
            <Submit height={`56`} textSize={`16`} colour={`green`} border>
              Save changes
            </Submit>
          </ButtonWrapper>
          <ButtonWrapper>
            <Cancel height={`56`} textSize={`16`} border onClick={() => setActive(false)}>
              Cancel
            </Cancel>
          </ButtonWrapper>
        </Buttons>
      </Wrapper>
    </>
  )
}

AccountAddressForm.propTypes = {
  address: PropTypes.shape(AddressProps),
  setActive: PropTypes.func,
}

export default AccountAddressForm
