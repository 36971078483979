import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { AddressProps } from "../../props/AccountProps"
import { StyledButton } from "../Styled/Button"
import Icon from "../Icon"

const Wrapper = tw.div`
  flex flex-row justify-between
`

const Details = tw.div`
  text-16 leading-1.75 flex flex-col
`

const Detail = styled.span`
  ${({ bold }) => (bold ? tw`font-medium text-18` : null)}
`

const Buttons = tw.div`
  md:flex flex-row hidden
`

const ButtonWrapper = tw.div`
  px-0-8
`

const Button = styled(StyledButton)`
  ${tw`w-15`}
`

const StyledIcon = styled(Icon)`
  ${tw`block md:hidden`}
`

const AccountAddress = ({ address, handleEdit, className }) => {
  return (
    <Wrapper className={className}>
      <Details>
        <Detail bold>{`${address.tag}${address.parimary ? `(Default)` : ""}`}</Detail>
        <Detail>
          {address.firstName} {address.lastName}
        </Detail>
        <Detail>{address.address1}</Detail>
        <Detail>{address.address2}</Detail>
        <Detail>
          {address.city} {address.postcode}
        </Detail>
        <Detail>{address.country}</Detail>
      </Details>
      <Buttons>
        <ButtonWrapper>
          <Button height={`56`} textSize={`16`} border onClick={() => handleEdit(address.id)}>
            Edit
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button height={`56`} textSize={`16`} border>
            Delete
          </Button>
        </ButtonWrapper>
      </Buttons>
      <StyledIcon name={`edit`} size={`xsmall`} onClick={() => handleEdit(address.id)} />
    </Wrapper>
  )
}

AccountAddress.propTypes = {
  address: PropTypes.shape(AddressProps),
  handleEdit: PropTypes.func,
  className: PropTypes.string,
}

export default AccountAddress
