import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { StyledButton } from "../Styled/Button"
import Icon from "../Icon"
import ModalsPopup from "../Modals/ModalsPopup"
import AccountDetailsForm from "./AccountDetailsForm"
import { UserProps, UserCustomProps } from "../../props/AccountProps"

const Wrapper = tw.div`
  min-h-full bg-lightest p-2-4 w-full md:p-3-6 lg:px-7-2 lg:py-4-8
`

const Title = tw.h2`
  mb-4-8 text-22 leading-1.32 hidden md:block font-medium
`

const Content = tw.div`
  flex flex-row justify-between
`

const Details = tw.div`
  text-16 leading-1.75 flex flex-col
`

const Edit = styled(StyledButton)`
  ${tw`max-w-15 w-full hidden md:block`}
`

const StyledIcon = styled(Icon)`
  ${tw`block md:hidden`}
`

const Desktop = tw.div`
  hidden md:block -mx-1-6
`

const Mobile = tw.div`
  md:hidden
`

const ModalsContent = tw.div`
  w-full py-3-2 px-0-8
`

const AccountDetails = ({ reference, user, userCustom }) => {
  const [editActive, setEditActive] = useState(false)
  const [editMobileActive, setEditMobileActive] = useState(false)
  return (
    <Wrapper>
      {!editActive ? (
        <>
          <Title>My details</Title>
          <Content>
            <Details>
              <span>{`${user.firstName} ${user.lastName}`}</span>
              <span>{user.email}</span>
              <span>{userCustom.phone}</span>
              <span>••••••••</span>
              <span>{`${userCustom.birthday.day} ${userCustom.birthday.month} ${userCustom.birthday.year}`}</span>
            </Details>
            <Edit
              height={`56`}
              textSize={`16`}
              colour={`lightest`}
              border
              onClick={() => setEditActive(true)}
            >
              Edit
            </Edit>
            <StyledIcon name={`edit`} size={`xsmall`} onClick={() => setEditMobileActive(true)} />
          </Content>
        </>
      ) : (
        <Desktop>
          <AccountDetailsForm
            reference={reference}
            user={user}
            userCustom={userCustom}
            setActive={setEditActive}
          />
        </Desktop>
      )}
      <Mobile>
        <ModalsPopup active={editMobileActive} setActive={setEditMobileActive}>
          <ModalsContent>
            <AccountDetailsForm reference={reference} user={user} userCustom={userCustom} />
          </ModalsContent>
        </ModalsPopup>
      </Mobile>
    </Wrapper>
  )
}

AccountDetails.propTypes = {
  reference: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.shape(UserProps),
  userCustom: PropTypes.shape(UserCustomProps),
}

export default AccountDetails
