import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import ModalsPopup from "../../Modals/ModalsPopup"
import FormInput from "../../Form/FormInput"
import { StyledButton } from "../../Styled/Button"
import { withBackInStock } from "./withBackInStock"

const Content = tw.div`
  pt-4 pb-6-4
`

const Form = tw.form`
  flex flex-col items-center justify-center 
`

const Title = styled.h2`
  ${tw`text-28 md:text-44 font-display`}
  ${({ submited }) => (submited ? tw`mb-1-6 text-center` : tw`mb-3-2`)}
`

const Description = styled.p`
  ${tw`max-w-27-2 mx-auto text-center leading-1.56`}
  ${({ submited }) => (submited ? tw`mb-1-6` : tw`mb-4-8`)}
`

const Input = styled(FormInput)`
  ${tw`mb-4-8 px-0`}
`

const Submit = styled(StyledButton)`
  ${tw`max-w-19 w-full`}
`

const BackInStock = withBackInStock(({ loading, sent, error, email, setEmail, handleSubmit, active, setActive }) => {
  return (
    <ModalsPopup active={active} setActive={setActive} notify>
      <Content>
        {!sent ? (
          <Form onSubmit={handleSubmit}>
            <Title>Notify me</Title>
            <Description>Enter your email below and you will receive an email once this item is back in stock.</Description>
            <Input
              tpye={`email`}
              name={`email`}
              placeholder={`Email`}
              placeholderColor={`dark`}
              value={email}
              handleChange={({ target: { value } }) => setEmail(value)}
            />
            <Submit type={`submit`} height={`56`} textSize={`16`} colour={`lightest`} border disabled={loading}>
              Send
            </Submit>
          </Form>
        ) : (
          <>
            <Title submited>Done!</Title>
            <Description submited>You’ll receive an email once this item is back in stock.</Description>
          </>
        )}
      </Content>
    </ModalsPopup>
  )
})

export default BackInStock
