import React from "react"

import PageTitle from "../../components/PageTitle/PageTitle"
import Account from "../../components/Account/Account"

const FakeData = {
  reference: [`Email`, `Friend`, `Other`],
  user: {
    firstName: `Anna`,
    lastName: `Baird`,
    email: `annabaird@bared.com.au`,
  },
  userCustom: {
    phone: `0430589475`,
    birthday: {
      day: `21`,
      month: `Apr`,
      year: `1989`,
    },
    reference: ``,
    healthProfessional: false,
    type: ``,
    name: ``,
    address: ``,
    city: ``,
    state: ``,
    postcode: ``,
    country: ``,
    ahpra: ``,
  },
  addresses: [
    {
      id: `1`,
      firstName: `Anna`,
      lastName: `Baird`,
      address1: `21 Robert Street`,
      address2: ``,
      city: `Collingwood`,
      state: `VIC`,
      postcode: `3066`,
      country: `Australia`,
      parimary: true,
      tag: `Home`,
    },
    {
      id: `2`,
      firstName: `Anna`,
      lastName: `Baird`,
      address1: `21 Robert Street`,
      address2: ``,
      city: `Collingwood`,
      state: `VIC`,
      postcode: `3066`,
      country: `Australia`,
      parimary: false,
      tag: `Work`,
    },
  ],
  orders: [
    {
      id: `1`,
      orderNumber: 233241658,
      totalPrice: {
        amount: 21995,
        currencyCode: `AUD`,
      },
      shippingTotalPrice: {
        amount: 1495,
        currencyCode: `AUD`,
      },
      subTotalPrice: {
        amount: 25900,
        currencyCode: `AUD`,
      },
      status: `In transit`,
      lineItems: [
        {
          image: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_3.png?v=1587469178`,
          title: `Babbler`,
          subTitle: `Cream leather`,
          selectedOptions: {
            name: `size`,
            value: `38`,
          },
          price: {
            amount: 25900,
            currencyCode: `AUD`,
          },
          quantity: 1,
        },
      ],
      processedAt: `26/03/2020`,
      link: `/`,
    },
    {
      id: `2`,
      orderNumber: 233123321,
      totalPrice: {
        amount: 53295,
        currencyCode: `AUD`,
      },
      shippingTotalPrice: {
        amount: 1495,
        currencyCode: `AUD`,
      },
      subTotalPrice: {
        amount: 51800,
        currencyCode: `AUD`,
      },
      status: `In transit`,
      lineItems: [
        {
          image: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_2_4.jpg?v=1589453112`,
          title: `Babbler`,
          subTitle: `Black leather`,
          selectedOptions: {
            name: `size`,
            value: `38`,
          },
          price: {
            amount: 25900,
            currencyCode: `AUD`,
          },
          quantity: 1,
        },
        {
          image: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_2_4.png?v=1589453112`,
          title: `Babbler`,
          subTitle: `Blue leather`,
          selectedOptions: {
            name: `size`,
            value: `39`,
          },
          price: {
            amount: 25900,
            currencyCode: `AUD`,
          },
          quantity: 1,
        },
      ],
      processedAt: `04/03/2020`,
      link: `/`,
      shippingAddress: {
        firstName: `Anna`,
        lastName: `Baird`,
        address1: `21 Robert Street`,
        address2: ``,
        city: `Collingwood`,
        state: `VIC`,
        postcode: `3066`,
        country: `Australia`,
      },
    },
  ],
  wishlistProducts: [
    {
      images: [
        {
          src: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_3.jpg?v=1588835297`,
        },
        {
          src: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_3_699670da-21c1-46c5-b21b-775ffe664e5e.jpg?v=1588835328`,
        },
        {
          src: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/BARED_FOOTWEAR_Babbler_Cream_259AUD_3_46dc7766-9e52-4cab-9ccb-b87730996ee4.jpg?v=1588835363`,
        },
        {
          src: `https://cdn.shopify.com/s/files/1/0322/6841/9208/files/Rectangle_198.png?v=1588835386`,
        },
      ],
      title: `Macaw`,
      subTitle: `Cornflower Blue Leather Heel`,
      variants: [
        {
          available: true,
          id: `1`,
          price: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          comparedPrice: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          quantityAvailable: 5,
          selectedOptions: [{ name: "size", value: "34" }],
        },
        {
          available: true,
          id: `2`,
          price: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          comparedPrice: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          quantityAvailable: 2,
          selectedOptions: [{ name: "size", value: "35" }],
        },
        {
          available: true,
          id: `3`,
          price: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          comparedPrice: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          selectedOptions: [{ name: "size", value: "36" }],
        },
        {
          available: true,
          id: `4`,
          price: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          comparedPrice: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          quantityAvailable: 5,
          selectedOptions: [{ name: "size", value: "37" }],
        },
        {
          available: true,
          id: `5`,
          price: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          comparedPrice: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          quantityAvailable: 5,
          selectedOptions: [{ name: "size", value: "38" }],
        },
        {
          available: true,
          id: `6`,
          price: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          comparedPrice: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          selectedOptions: [{ name: "size", value: "39" }],
        },
        {
          available: true,
          id: `7`,
          price: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          comparedPrice: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          selectedOptions: [{ name: "size", value: "40" }],
        },
        {
          available: false,
          id: `8`,
          price: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          comparedPrice: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          selectedOptions: [{ name: "size", value: "41" }],
        },
        {
          available: true,
          id: `9`,
          price: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          comparedPrice: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          selectedOptions: [{ name: "size", value: "42" }],
        },
        {
          available: true,
          id: `10`,
          price: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          comparedPrice: [
            {
              amount: 25900,
              currencyCode: `AUD`,
            },
            {
              amount: 16900,
              currencyCode: `USD`,
            },
            {
              amount: 15900,
              currencyCode: `EUR`,
            },
            {
              amount: 13900,
              currencyCode: `GBP`,
            },
            {
              amount: 1810000,
              currencyCode: `JPY`,
            },
          ],
          selectedOptions: [{ name: "size", value: "43" }],
        },
      ],
      link: `/`,
      notify: true,
    },
  ],
}

const DashboardPage = () => {
  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "My account",
    },
  ]
  return (
    <>
      <PageTitle title={`Good to see you again`} paths={paths} />
      <Account
        reference={FakeData.reference}
        user={FakeData.user}
        userCustom={FakeData.userCustom}
        orders={FakeData.orders}
        addresses={FakeData.addresses}
        wishlistProducts={[
          FakeData.wishlistProducts[0],
          FakeData.wishlistProducts[0],
          FakeData.wishlistProducts[0],
        ]}
      />
    </>
  )
}

export default DashboardPage
