import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { OrderProps } from "../../props/AccountProps"
import Image from "../Image/Image"
import { StyledButton } from "../Styled/Button"
import Icon from "../Icon"
import Money from "../Money"

const Wrapper = tw.div`
  relative
`

const Close = styled(Icon)`
  ${tw`hidden md:block absolute -top-1-6 -right-1-6 cursor-pointer`}
`

const Title = styled.h2`
  ${tw`mb-1-6 text-18 md:text-22 leading-1.32 font-medium px-1-6`}
  ${({ marginTop }) => (marginTop ? tw`mt-2-4` : null)}
`

const Text = styled.p`
  ${({ description }) => (description ? tw`text-14 leading-1.81 md:text-16 md:leading-2` : null)}
  ${({ status }) => (status ? tw`text-orange text-16 leading-2 font-medium` : null)}
  ${({ date }) => (date ? tw`text-13 leading-1.69` : null)}
  ${({ bold }) => (bold ? tw`font-medium` : null)}
  ${({ title }) => (title ? tw`text-18 md:text-22 leading-1.32 font-medium` : null)}
`

const DetailWrapper = tw.div`
  w-full py-2-4 px-1-6 flex flex-col md:flex-row border-b border-beige
`

const Detail = styled.div`
  ${tw`flex flex-col w-full md:w-1/2`}
  ${({ start }) => (start ? tw`items-start` : null)}
  ${({ end }) => (end ? tw`items-end` : null)}
  ${({ row }) => (row ? tw`md:flex-row` : null)}
`

const LineItemsWrapper = tw.div`
  px-1-6 border-b border-beige
`

const LineItem = tw.div`
  flex flex-row items-center
`

const LineItemImageWrapper = tw.div`
  w-1/2 flex justify-center p-0-8
`

const ImageWrapper = tw.div`
  w-full md:w-1/2
`

const LineItemDetailWrapper = tw.div`
  w-1/2 flex flex-wrap p-0-8
`

const LineItemDetail = tw.div`
  w-full md:w-1/2
`

const PriceDetailsWrapper = tw.div`
  flex justify-end
`

const PriceDetails = tw.div`
  w-full md:w-1/2 border-t border-beige flex flex-wrap py-2-4
`

const PriceDetail = tw.div`
  w-1/2
`

const ShippingDetailsWrapper = tw.div`
  w-full py-2-4 px-1-6 flex flex-col md:flex-row
`

const ShippingDetail = tw.div`
  w-full md:w-1/2
`

const StyledLink = styled(Link)`
  ${tw`text-12 underline mt-1-2 mr-0-8`}
`

const Buttons = tw.div`
  flex flex-col md:flex-row w-full mt-2-4 md:mt-0
`

const ButtonWrapper = tw.div`
  md:px-0-8 py-0-4
`

const Button = styled(StyledButton)`
  ${tw`w-full md:w-15`}
`

const AccountOrderDetails = ({ order, setActive }) => {
  return (
    <Wrapper>
      <Close name={`close`} onClick={() => setActive(false)} />
      <Title>{`Order #${order.orderNumber}`}</Title>
      <DetailWrapper>
        <Detail start>
          <Text status>{order.status}</Text>
          <Text date>{`Purchased ${order?.shippingAddress ? `online` : `in store`} on ${
            order.processedAt
          }`}</Text>
        </Detail>
        <Detail end>
          <Buttons>
            <ButtonWrapper>
              <Button height={`56`} textSize={`16`} border>
                View Order
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button height={`56`} textSize={`16`} border>
                Track Order
              </Button>
            </ButtonWrapper>
          </Buttons>
          <StyledLink to={`/`}>Return information</StyledLink>
        </Detail>
      </DetailWrapper>
      <Title marginTop>
        {`${order.lineItems.length} ${order.lineItems.length === 1 ? `item` : `items`}`}
      </Title>
      <LineItemsWrapper>
        {order.lineItems.map((lineItem, index) => {
          return (
            <LineItem key={index}>
              <LineItemImageWrapper>
                <ImageWrapper>
                  <Image image={lineItem.image} ratio={`1/1`} />
                </ImageWrapper>
              </LineItemImageWrapper>
              <LineItemDetailWrapper>
                <LineItemDetail>
                  <Text description>{lineItem.title}</Text>
                  <Text description>{lineItem.subTitle}</Text>
                  <Text description>
                    {`${lineItem.selectedOptions.name} ${lineItem.selectedOptions.value}`}
                  </Text>
                </LineItemDetail>
                <LineItemDetail>
                  <Text description>
                    <Money
                      amount={lineItem.price.amount}
                      currencyCode={lineItem.price.currencyCode}
                    />
                  </Text>
                  <Text description>{`Qty: ${lineItem.quantity}`}</Text>
                </LineItemDetail>
              </LineItemDetailWrapper>
            </LineItem>
          )
        })}
        <PriceDetailsWrapper>
          <PriceDetails>
            <PriceDetail>
              <Text description>Sub total</Text>
              <Text description>Shipping</Text>
              <Text bold description>
                Total
              </Text>
            </PriceDetail>
            <PriceDetail>
              <Text description>
                <Money
                  amount={order.subTotalPrice.amount}
                  currencyCode={order.subTotalPrice.currencyCode}
                />
              </Text>
              <Text description>
                <Money
                  amount={order.shippingTotalPrice.amount}
                  currencyCode={order.shippingTotalPrice.currencyCode}
                />
              </Text>
              <Text bold description>
                <Money
                  amount={order.totalPrice.amount}
                  currencyCode={order.totalPrice.currencyCode}
                />
              </Text>
            </PriceDetail>
          </PriceDetails>
        </PriceDetailsWrapper>
      </LineItemsWrapper>
      {order?.shippingAddress && (
        <ShippingDetailsWrapper>
          <ShippingDetail>
            <Text title>Shipping Details</Text>
          </ShippingDetail>
          <ShippingDetail>
            <Text description>
              {`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}
            </Text>
            <Text description>{order.shippingAddress.address1}</Text>
            <Text description>{order.shippingAddress.address2}</Text>
            <Text description>
              {`${order.shippingAddress.city} ${order.shippingAddress.postcode}`}
            </Text>
            <Text description>{order.shippingAddress.country}</Text>
          </ShippingDetail>
        </ShippingDetailsWrapper>
      )}
    </Wrapper>
  )
}

AccountOrderDetails.propTypes = {
  order: PropTypes.shape(OrderProps),
  setActive: PropTypes.func,
}

export default AccountOrderDetails
