import React, { useState } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { StyledButton } from "../Styled/Button"
import ModalsPopup from "../Modals/ModalsPopup"
import AccountAddress from "./AccountAddress"
import AccountAddressFrom from "./AccountAddressForm"
import { AddressesProps } from "../../props/AccountProps"

const Wrapper = tw.div`
  min-h-full bg-lightest p-2-4 pt-0-8 w-full md:p-3-6 lg:px-7-2 lg:py-4-8
`

const Title = tw.h2`
  mb-2-4 text-22 leading-1.32 hidden md:block font-medium
`

const Content = tw.div`
  flex flex-col
`

const Address = styled(AccountAddress)`
  ${tw`py-2-4`}
  ${({ last }) => (last ? tw`border-0` : tw`border-b border-beige`)}
`

const Button = styled(StyledButton)`
  ${tw`md:max-w-30 w-full`}
`

const Desktop = tw.div`
  hidden md:block -mx-1-6
`

const Mobile = tw.div`
  md:hidden
`

const ModalsContent = tw.div`
  w-full py-3-2 px-0-8
`

const INITIAL_STATE = {
  id: "",
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postcode: "",
  country: "",
  parimary: false,
  tag: "",
}

const AccountAddresses = ({ addresses }) => {
  const [editActive, setEditActive] = useState(false)
  const [addressData, setAddressData] = useState(INITIAL_STATE)

  const handleClick = id => {
    setAddressData(id ? { ...addresses.find(address => address.id === id) } : INITIAL_STATE)
    setEditActive(true)
  }

  return (
    <Wrapper>
      {!editActive ? (
        <>
          <Title>My addresses</Title>
          <Content>
            {addresses.map((address, index) => (
              <Address key={address.id} address={address} handleEdit={handleClick} last={index === addresses.length - 1} />
            ))}
          </Content>
          <Button height={`56`} textSize={`16`} colour={`green`} border onClick={handleClick}>
            Add new address
          </Button>
        </>
      ) : (
        <Desktop>
          <AccountAddressFrom address={addressData} setActive={setEditActive} />
        </Desktop>
      )}
      <Mobile>
        <ModalsPopup active={editActive} setActive={setEditActive}>
          <ModalsContent>
            <AccountAddressFrom address={addressData} setActive={setEditActive} />
          </ModalsContent>
        </ModalsPopup>
      </Mobile>
    </Wrapper>
  )
}

AccountAddresses.propTypes = {
  addresses: AddressesProps,
}

export default AccountAddresses
